* {
	-webkit-box-sizing: border-box; // Safari <= 5
	-moz-box-sizing: border-box; // Firefox <= 19
	box-sizing: border-box;
}

html {
	margin: 0;
	padding: 0;
}

body{
	margin: 0;
	padding: 0;
}
