$body-background: #ffffff;
$color-default-text: #222;
$color-dark-blue: #072938;
$color-light-blue: #acc5d4;


$color-primary: #00757f;
$color-primary-hover: #00676f;

$color-default: #f2f1f0;
$color-default-hover: #e9e7e5;

$color-success: #43ac6a;
$color-success-hover: #368a55;

$color-warning: #c12732;
$color-warning-hover: #a2220b;
$color-light-warning: #ffeaeb;

$color-cyan: #a0d3e8;
$color-cyan-hover: #61b6d9;
$color-light-cyan: #ecf2f6;
$color-light-cyan-hover: #dee9ef;

$color-orange: #f08a24;
$color-orange-hover: #cf6e0e;
$color-light-orange: #faefdf;
$color-light-orange-hover: #f7e4c9;

$color-lime: #c9d12b;
$color-lime-hover: #b5bc27;
$color-light-lime: #f0f2cb;
$color-light-lime-hover: #ebeebb;
