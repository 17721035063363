@import "../style/global.scss";

:local(.isPresent){

	:local(.navigationBar){
		background-color: $color-dark-blue;
		height: 54px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media only screen and (min-width: $screen-xs) {
			height: 73px;
		}
	}

	:local(.logoContainer){
		width: 200px;
		height: 54px;
		display: inline-block;
		img {
			height: 54px;
		}
		@media only screen and (min-width: $screen-xs) {
			width: 270px;
			height: 73px;
			img {
				height: 73px;
			}
		}
	}

	:local(.childElements){
		display: block;
		color: #fff;
		flex: 1;
	}

	:local(.dropdownOverlay){
		@media only screen and (max-width: $screen-md) and (min-width: $screen-xs) {
			@include transition(all .1s ease-out);
			content: '';
			position: absolute;
			top: 73px;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
			visibility: hidden;
			opacity: 0;
			background: transparent;
			&:local(.active){
				opacity: 1;
				background: rgba(0,0,0,.5);
				visibility: visible;
			}
		}
	}

	:local(.menuToggle){
		@include appearance(none);
		@include transition(background-color 300ms ease-out);
		border: none;
		cursor: pointer;
		position: relative;
		background-color: transparent;
		background: url(../images/hamburger.svg) 50% 50% no-repeat;
		width: 30px;
		height: 30px;
		float: right;
		margin: 0 12px;
		&:local(.active) {
			background: url(../images/hamburger-hover.svg) 50% 50% no-repeat;
		}
		@media only screen and (min-width: $screen-xs){
			margin: 0 21px;
		}
	}

	:local(.dropdownContainer){
		@include transition(max-height 0.15s ease-out);
		max-height: 0;
		overflow: hidden;
		&:local(.active){
			@include transition(max-height 0.15s ease-out);
			max-height: 500px;
		}
	}

	:local(.dropdown){
		background-color: $color-dark-blue;
		z-index: 2;
		>:local(ul.primaryList){
			list-style: none;
			padding: 20px;
			margin: 0;
			li{
				a, span{
					color: #fff;
					text-transform: uppercase;
					text-decoration: none;
					font-family: "Open Sans";
					padding: 10px 0;
					display: block;
					border-bottom: 1px solid $color-primary;
					font-size: 14px;
					line-height: 1.6;
				}
				>:local(ul.primaryList){
					list-style: none;
					padding: 0;
					li{
						a, span{
							color: #fff;
							text-transform: none;
							text-decoration: none;
							font-family: "Open Sans";
							padding: 10px 0;
							margin-left: 20px;
							display: block;
							border-bottom: 1px solid #1b3a48;
							font-size: 14px;
							line-height: 1.6;
						}
					}
				}

			}
		}
		>:local(ul.secondaryList){
			list-style: none;
			padding: 20px;
			margin: 0;

			li {
				a, span{
					color: #fff;
					text-decoration: underline;
					font-family: "Arial";
					font-size: 14px;
					padding: 10px 0;
					line-height: 1;
				}
			}
		}
	}

	@media only screen and (min-width: $screen-xs) {
		:local(.dropdownContainer){
			position: relative;
			overflow: visible;
			:local(.dropdown){
				@include transition(max-height 0.15s ease-out);
				width: 300px;
				position: absolute;
				right: 0;
				max-height: 0;
				overflow: hidden;
			}
			&:local(.active){
				:local(.dropdown){
					max-height: 500px;
				}
			}

		}
	}
}
