@import "../style/global.scss";

:local(.radioButtonIcon) {
  @include border-radius(50%);
  @include box-shadow(0 0 0 1px #afaba8);
  @include transition(all 0.1s ease-in-out);
  @include box-sizing(content-box);
  display: inline-block;
  vertical-align: middle;
  min-width: 10px;
  width: 10px;
  height: 10px;
  background: white;
  margin-right: .4em;
  border: 0.3em solid white;

  &:local(.checked) {
    @include box-shadow(0 0 0 1px $color-dark-blue);
    background: $color-primary;
  }
}
