@import "../style/global.scss";

:local(.checkBoxIcon) {
  @include border-radius(0);
  @include transition(all 0.1s ease-in-out);
  @include box-sizing(content-box);
  display: inline-block;
  vertical-align: middle;
  min-width: 10px;
  width: 10px;
  height: 10px;
  min-width: 20px;
  background: white;
  margin-right: .2em;
  line-height: 1;
  text-align: center;

  &:local(.showBox) {
    @include box-shadow(0 0 0 1px #afaba8);
    margin-right: .4em;
  }

  &:local(.checked) {
    color: $color-primary;
    &:local(.showBox) {
      @include box-shadow(0 0 0 1px $color-dark-blue);
    }
  }

  :local(.checkmark) {
    font-size: 1em;
    line-height: 0.75em;
  }
}
