@import "../style/global.scss";

:local(.radioButtonListItem) {
  @include box-sizing(content-box);
  @include box-shadow(0 0 2px $color-dark-blue);
  @include border-radius(5px);
  @include transition(all 0.1s ease-in-out);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  cursor: pointer;
  font-family: $default-font;
  position: relative;
  margin-top: 10px;
  background: white;
  font-size: 1rem;
  color: #000;

  &:local(.checked) {
    @include box-shadow(0 0 0 1px $color-dark-blue, 0 0 4px 0 $color-dark-blue);
  }

  &:not(:local(.checked)):hover {
    @include box-shadow(0 0 6px $color-dark-blue);
  }

  &:focus {
    outline-color: #54acb8;
  }

  label {
    width: 100%;
    padding: 10px;
    margin: 0;
    cursor: pointer;
  }

  input[type="radio"] {
    opacity: 0;
    position: absolute;
  }
}
