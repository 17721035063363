@import "../style/global.scss";

:local(.dragAndDropFileInput) {
  label {
    cursor: pointer;
    font-size: 18px;
    margin: 0 0 4px;
    font-family: "Open Sans",arial,sans-serif;
    line-height: 1.3;
    font-weight: bold;
    font-style: normal;
    display: block;
  }

  :local(.dragAndDropContainer) {
    @include transition(all 0.1s ease-in-out);
    border: 2px dashed #ccc;
    border-radius: 20px;
    width: 100%;
    padding: 20px;
    text-align: center;

    input {
      display: none;
    }

    button {
      margin: 14px 0 0;
    }

    &:local(.highlighted) {
      background: $color-default;
      border-color: $color-dark-blue;
    }
  }
}
