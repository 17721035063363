:local(.headerContainer){
	font-size: 22px;
	padding: 10px 0;
	:local(.header){
		margin: 0;
		font-weight: normal;
		line-height: 1.4;
		font-style: normal;
		font-size: 18px;
	}
	:local(h1.header){
		font-family: "Altis-Light","Open Sans",arial,sans-serif;
		font-size: 50px;
		line-height: 1.2;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	:local(h2.header){
		font-family: "Open Sans",arial,sans-serif;
		font-size: 24px;
		font-weight: bold;
		line-height: 1.3;
	}
	:local(h3.header){
		font-family: "Open Sans",arial,sans-serif;
		font-size: 18px;
		font-weight: bold;
		line-height: 1.3;
	}
}
