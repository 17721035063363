@import "../style/global.scss";

:local(.radioButtonInput) {
  display: flex;
  cursor: pointer;

  input[type="radio"] {
    opacity: 0;
    position: absolute;
  }
  span {
    font-weight: 300;
  }
  margin-right: 1em;
}
