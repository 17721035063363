@import "../style/global.scss";

:local(.contentBox) {
    font-family: $default-font;
    text-decoration: none;
    padding: 20px;
    width: 100%;
    display: block;
    &:local(.link){
        color: $color-primary;
        @include transition(background-color .3s);
    }
    &:local(.default) {
        background-color: $color-default;
        &:local(.link):hover {
            background-color: $color-default-hover;
        }
        :local(.content){
            color: #000;
        }
    }
    &:local(.primary) {
        background-color: $color-primary;
        color: #fff;
        &:local(.link):hover {
            background-color: $color-primary-hover;
        }
        :local(.content){
            color: #fff;
        }
    }
    &:local(.success) {
        background-color: $color-success;
        color: #fff;
        &:local(.link):hover {
            background-color: $color-success-hover;
        }
        :local(.content){
            color: #fff;
        }   
    }
    &:local(.warning) {
        background-color: $color-warning;
        color: #fff;
        &:local(.link):hover {
            background-color: $color-warning-hover;
        }
        :local(.content){
            color: #fff;
        }
    }

    &:local(.cyan) {
        background-color: $color-cyan;
        &:local(.link):hover {
            background-color: $color-cyan-hover;
        }
        :local(.content){
            color: #000;
        }
    }
    &:local(.lightCyan) {
        background-color: $color-light-cyan;
        &:local(.link):hover {
            background-color: $color-light-cyan-hover;
        }
        :local(.content){
            color: #000;
        }
    }
    &:local(.orange) {
        background-color: $color-orange;
        color: #fff;
        &:local(.link):hover {
            background-color: $color-orange-hover;
        }
        :local(.content){
            color: #fff;
        }
    }
    &:local(.lightOrange) {
        background-color: $color-light-orange;
        &:local(.link):hover {
            background-color: $color-light-orange-hover;
        }
        :local(.content){
            color: #000;
        }
    }
    &:local(.lime) {
        background-color: $color-lime;
        color: #fff;
        &:local(.link):hover {
            background-color: $color-lime-hover;
        }
        :local(.content){
            color: #fff;
        }
    }
    &:local(.lightLime) {
        background-color: $color-light-lime;
        &:local(.link):hover {
            background-color: $color-light-lime-hover;
        }
        :local(.content){
            color: #000;
        }
    }

    :local(.title){        
        &:local(.regular) {
            font-family: "Open Sans";            
            font-weight: bold;
            font-size: 21px;
            font-style: normal;
            line-height: 1.4;
            padding-bottom: 10px;
            border-bottom: 1px solid #e5e3e1;
        }
        &:local(.large){
            font-family: "Open Sans";
            font-weight: normal;
            font-size: 24px;
            margin: 0;
            margin-bottom: 20px;
            font-style: normal;
        }
    }
}